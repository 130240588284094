<template>
  <div class="row" v-if="showChat">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <chat-on-receipts :driver="selectedDriver" :file="selectedExtFile"
                            @onBackPressed="closeDriverChat"></chat-on-receipts>
        </div>
      </div>
    </div>

  </div>
  <div class="row" v-else>
    <div class="col-12" v-if="!disableAdvanceSearch">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title mb-4">Search Receipt ({{ driverExtFiles.length }}) </h4>
          <form @submit.prevent="searchReceipt">
            <div class="row mb-2" v-for="(d,i) in search_data" :key="i">
              <div class="col-md-3">
                <select class="form-control" v-model="d.column" required @change="selectOptions(i)">
                  <option value="id">Doc Id</option>
                  <option value="current_status">Status</option>
                  <option value="approved_by">Approved By</option>
                  <option value="approved_by_date">Approved Date</option>
                  <option value="accepted_by">Accepted By</option>
                  <option value="accepted_by_date">Accepted Date</option>
                  <option value="declined_by">Declined By</option>
                  <option value="declined_by_date">Declined Date</option>
                  <option value="current_status_comment">current_status_comment</option>
                  <option value="current_status_update_date">current_status_update_date</option>
                  <!--                  <option value="current_status_updated_by">current_status_updated_by</option>-->
                  <option value="invoice_number">invoice_number</option>
                  <option value="auth_code">auth_code</option>
                  <option value="created_at">created_at</option>
                  <option value="updated_at">updated_at</option>
                </select>
              </div>
              <div class="col-md-2" v-if="d.column === 'current_status'">
                <div class="form-group">
                  <select name="" class="form-control" v-model="d.input"
                  >
                    <option value="">All</option>
                    <option value="pending">view pending files</option>
                    <option value="accepted">view accepted files</option>
                    <option value="declined">view declined files</option>
                    <option value="approved">view approved files</option>
                    <option value="unclear">view unclear files</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2"
                   v-if="d.column === 'approved_by' || d.column === 'accepted_by' || d.column === 'declined_by'">
                <div class="form-group">
                  <multiselect v-model="d.input" :options="admins.map(admin => admin.id)"
                               :custom-label="opt => admins.find(x => x.id == opt).name"
                               :multiple="false"></multiselect>

                </div>
              </div>

              <div class="col-md-3"
                   v-if="d.column !== 'current_status' && d.column !== 'approved_by' && d.column !== 'accepted_by' && d.column !== 'declined_by'">
                <select class="form-control" v-model="d.operator" required>
                  <option value="=">=</option>
                  <option value="<"><</option>
                  <option value=">"> ></option>
                  <option value="<="> <=</option>
                  <option value=">="> >=</option>
                  <option value="IS NULL">IS NULL</option>
                  <option value="IS NOT NULL">IS NOT NULL</option>
                  <option value="LIKE">contains</option>
                  <option value="NOT LIKE">Not contains</option>
                </select>
              </div>
              <div class="col-md-3"
                   v-if="d.column !== 'current_status' && d.column !== 'approved_by' && d.column !== 'accepted_by' && d.column !== 'declined_by'">
                <input type="text" class="form-control" v-model="d.input"/>
              </div>
              <div class="col-md-3">
                <button class="btn mr-2 btn-light" v-if="search_data.length > 1" @click="removeSearch(i)"><i
                    class="fa fa-minus"></i></button>
                <button class="btn btn-light" v-if="i === search_data.length-1" @click="addSearch()"><i
                    class="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6 text-left">
                <b-button size="sm" variant="info" @click="downloadReceiptData"><i class="fa fa-download"/> Download
                  Data
                </b-button>
                <b-button size="sm" class="ml-2" variant="danger" @click="bulkReceiptApproval"><i
                    class="fa fa-handshake"/> Approve all accepted receipts
                </b-button>
              </div>

              <div class="col-md-3">
                <button type="submit" class="btn btn-dark mr-2">Search</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-body">

          <h4 class="card-title">Generated Links <small class="text-danger">(download links are available to 10 mins for
            security purposes)</small></h4>
          <table class="table ">
            <template v-for="(links) in tokens">
              <template>
                <tr>
                  <td>Generated {{ getReceiptDownloadDate(links['created_at']) }}</td>
                  <td class="text-success">{{ links['status'] }}</td>
                  <td><a :href="getDownloadUrl(links['token'])" @click="onDownloadLinkTapped(links['token'])">Click to
                    download</a></td>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>

    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="">
            <!--              <Toolbar />-->
            <template>
              <div>
                <div class="mt-0">
                  <div class="row">
                    <div class="col-md-4">
                      <form @submit.prevent="searchByInvoice">
                        <div class="row">
                          <div class="col-8">
                            <div class="form-group">
                              <label>Invoice no.</label>
                              <b-input id="inline-form-input-name" required v-model="search"
                                       placeholder="Search Invoice no."></b-input>
                            </div>
                          </div>
                          <div class="col-4">
                            <br>
                            <button type="submit" class="btn btn-info mt-1">Search</button>
                          </div>
                        </div>
                      </form>

                    </div>
                    <div class="col-md-4">
                      <form @submit.prevent="searchByAuthCode">
                        <div class="row">
                          <div class="col-8">
                            <div class="form-group">
                              <label>Auth code</label>
                              <b-input id="inline-form-input-name" required v-model="authCode"
                                       placeholder="Search Auth Code"></b-input>
                            </div>
                          </div>
                          <div class="col-4">
                            <br>
                            <button type="submit" class="btn btn-info mt-1">Search</button>
                          </div>
                        </div>
                      </form>
                    </div>


                  </div>
                  <form @submit.prevent="fetchDriverExtFiles">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Driver</label>
                          <multiselect v-model="selectedDrivers" :options="drivers"
                                       label="name" track-by="name" :multiple="true"></multiselect>

                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Status</label>
                          <select name="" class="form-control" v-model="status"
                          >
                            <option value="">All</option>
                            <option value="pending">view pending files</option>
                            <option value="accepted">view accepted files</option>
                            <option value="declined">view declined files</option>
                            <option value="approved">view approved files</option>
                            <option value="unclear">view unclear files</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>From</label>
                          <b-form-datepicker id="start-datepicker"
                                             v-model="startDate"></b-form-datepicker>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>To</label>
                          <b-form-datepicker id="end-datepicker"
                                             v-model="endDate"></b-form-datepicker>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <br>
                        <button type="submit" class="btn btn-success mt-1">Submit</button>
                      </div>
                    </div>
                  </form>
                  <hr>
                  <div class="row mb-4" v-if="driverExtFiles.length>0">
                    <div class="col">
                      <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item" :class="[link.active?'active':'']" :key="i"
                                v-for="(link,i) in ((receipts || {}).meta || {}).links">
                              <a @click.prevent="goToPage(link)" style="cursor: pointer;" class="page-link"><span
                                  v-html="link.label"></span></a>
                            </li>
                          </ul>
                        </nav>

                      </div>
                    </div>
                  </div>

                  <div class="row">

                    <template v-if="driverExtFiles.length === 0"
                    >
                      <h4 v-if="otherSearchFields">No receipts {{ status }} <span v-if="startDate"> from
                        {{ formattedDate(startDate) }} to {{ formattedDate(endDate) }}</span></h4>
                      <h4 v-if="searchByInvoiceColumn">No receipts found for invoice no. {{ search }}</h4>
                    </template>

                    <template v-for="(file, index) in itemsForList">
                      <div class="col-sm-3" @click="onExternalFileTapped(file, index)">
                        <div :class="{'selected': selectedExtFileIndex === index}">
                          <b-img
                              thumbnail
                              :src="file.filePath"
                              width="200"
                              height="400"
                              style="object-fit: contain"
                              alt="image"
                          ></b-img>
                          <p class="mb-0">
                            <code class="mb-2">{{ file['createdAt'] }}</code>
                          <h5>{{ file.driver_user.name }}</h5>
                          <br>
                          <span class="badge p-1"
                                :class="{'badge-warning': (file.current_status !== 'approved' && file.current_status !== 'accepted'), 'badge-success': (file.current_status === 'approved' || file.current_status === 'accepted')}"
                          >{{ file.current_status }}</span>
                          <template v-if="file['countPendingMessagesFromDriver'] > 0">
                              <span
                                  class="badge badge-pill badge-success float-right mb-2 mr-2"> {{
                                  file['countPendingMessagesFromDriver']
                                }}</span>
                          </template>
                        </div>
                      </div>
                    </template>

                  </div>
                  <div class="row mt-4" v-if="driverExtFiles.length>0" id="details">
                    <div class="col">
                      <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item" :class="[link.active?'active':'']" :key="i"
                                v-for="(link,i) in ((receipts || {}).meta || {}).links">
                              <a @click.prevent="goToPage(link)" style="cursor: pointer;" class="page-link"><span
                                  v-html="link.label"></span></a>
                            </li>
                          </ul>
                        </nav>

                      </div>
                    </div>
                  </div>

                </div>

                <!--   Selected file details -->
                <template v-if="selectedExtFile">

                  <hr>

                  <div class="mt-4">

                    <div class="media mb-3 mt-1">
                      <div class="media-body">
                        <small class="float-right">{{ selectedExtFile.createdAt }}</small>
                        <h6 class="m-0 font-14">{{ selectedExtFile.file_display_name }}</h6>
                        <h5>{{ ((selectedExtFile || {}).driver_user || {}).name }}</h5>
                        <!--                          <small class="text-muted">From: {{driver.name}} {{driver.other_names}}</small>-->
                      </div>
                    </div>
                  </div>
                  <!--              Tapped Image should show here-->
                  <div class="row">
                    <div class="col-md-6">
                      <image-magnifier
                          :src="selectedExtFile.filePath"
                          :zoom-src="selectedExtFile.filePath"
                          style="width: 100%;"
                          height="350"
                          zoom-width="500"
                          zoom-height="500"
                      >
                      </image-magnifier>


                    </div>
                    <div class="col-md-6">
                      <b-dropdown class="btn-group mr-1" size="sm" variant="light">
                        <template slot="button-content">
                          <i class="mdi mdi-dots-horizontal font-18"></i> Click for more actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item @click="openDriverChat">Chat driver</b-dropdown-item>
                        <b-dropdown-item v-if="!disableAction" @click="updateExternalFileStatus('accepted')">Accept
                          receipt
                        </b-dropdown-item>
                        <b-dropdown-item v-if="!disableAction" @click="updateExternalFileStatus('declined')">Decline
                          receipt
                        </b-dropdown-item>
                        <b-dropdown-item v-if="!disableAction" @click="updateExternalFileStatus('unclear')">Mark as
                          unclear
                        </b-dropdown-item>
                      </b-dropdown>
                      <table class="table mt-3">
                        <tr>
                          <td>Status</td>
                          <td class="text-right"><span class="badge badge-success p-1"
                                                       :class="{'badge-warning': (selectedExtFile.current_status !== 'approved' && selectedExtFile.current_status !== 'accepted'), 'badge-success': (selectedExtFile.current_status === 'approved' || selectedExtFile.current_status === 'accepted' )}"
                          >{{ selectedExtFile.current_status }}</span></td>
                        </tr>
                        <tr v-if="selectedExtFile['current_status'] === 'accepted'">
                          <td>Accepted by</td>
                          <td class="text-right">
                            {{
                              (selectedExtFile.statusAcceptedBy && (selectedExtFile.statusAcceptedBy !== 'N/A')) ? selectedExtFile.statusAcceptedBy.name : 'N/A'
                            }} <span
                              v-if="selectedExtFile.statusAcceptedDate !== 'N/A'">({{ selectedExtFile.statusAcceptedDate | toFormattedDateTime }})</span>
                          </td>
                        </tr>
                        <tr v-if="selectedExtFile['current_status'] === 'approved'">
                          <td>Approved by</td>
                          <td class="text-right">
                            {{
                              (selectedExtFile['statusApprovedBy'] && (selectedExtFile.statusApprovedBy !== 'N/A')) ? selectedExtFile.statusApprovedBy.name : 'N/A'
                            }} <span
                              v-if="selectedExtFile.statusApprovedDate !== 'N/A'">({{ selectedExtFile.statusApprovedDate | toFormattedDateTime }})</span>
                          </td>
                        </tr>
                        <tr v-if="selectedExtFile['current_status'] === 'declined'">
                          <td>Declined by</td>
                          <td class="text-right">
                            {{
                              (selectedExtFile['statusDeclinedBy'] && (selectedExtFile.statusDeclinedBy !== 'N/A')) ? selectedExtFile.statusDeclinedBy.name : 'N/A'
                            }} <span
                              v-if="selectedExtFile.statusDeclinedDate !== 'N/A'">({{ selectedExtFile.statusDeclinedDate | toFormattedDateTime }})</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Status Assigned by</td>
                          <td class="text-right">
                            {{
                              (selectedExtFile.statusUpdatedBy && (selectedExtFile.statusUpdatedBy !== 'N/A')) ? selectedExtFile.statusUpdatedBy.name : 'N/A'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Status Assigned on</td>
                          <td class="text-right">
                            {{
                              selectedExtFile.current_status !== 'pending' && selectedExtFile.statusUpdatedDate ? selectedExtFile.statusUpdatedDate : 'N/A'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Comment</td>
                          <td class="text-right"> {{ selectedExtFile['current_status_comment'] || 'N/A' }}</td>
                        </tr>
                        <template
                            v-if="selectedExtFile['current_status'] === 'accepted' && !selectedExtFile['data_on_ext_receipts']">
                          <tr>
                            <td colspan="2">
                              <h4 class="text-bold card-title text-danger">Please save the receipt information
                                below</h4>
                            </td>
                          </tr>
                        </template>
                      </table>

                    </div>
                  </div>
                  <hr/>
                  <form @submit.prevent="handleSubmit" method="post">
                    <h5 class="font-18">
                      Enter receipt info
                    </h5>
                    <div class="mt-3">
                      <h6 class="m-0 font-14">Basic Information</h6>
                      <hr>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="invoice_number">Invoice number</label>
                            <input type="text" class="form-control" id="invoice_number"
                                   placeholder="Enter invoice number here"
                                   v-model="form.invoiceNumber"
                            >
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="invoice_date">Date on Invoice</label>
                            <input type="date" class="form-control" id="invoice_date"
                                   placeholder="Enter date on the invoice"
                                   v-model="form.invoiceDate"
                                   required>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="invoice_place_name">Name of place</label>
                            <input type="text" class="form-control" id="invoice_place_name"
                                   placeholder="Enter place here"
                                   v-model="form.invoicePlace"
                                   required
                            >
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="invoice_number">Auth code</label>
                            <input type="text" class="form-control" placeholder="Enter authorization code here"
                                   v-model="form.authCode"
                            >
                          </div>
                        </div>
                      </div>

                      <template v-if="configuredFields">

                        <h6 class="m-0 font-14 mt-3">Fill the form below for financial administration & Weekly
                          payment</h6>
                        <hr>
                        <div class="row">
                          <div class="col-md-3 mt-4">
                            <label>Receipt file</label>

                            <image-magnifier
                                :src="selectedExtFile.filePath"
                                :zoom-src="selectedExtFile.filePath"
                                style="width: 100%;"
                                height="350"
                                zoom-width="500"
                                zoom-height="500"
                            >
                            </image-magnifier>
                          </div>
                          <div class="col-md-9">
                            <p>NB : All fields marked grey will be assigned a value of zero(0) after submitting the
                              form</p>

                            <div class="row">
                              <template v-for="(field, index) in configuredFields.calculatedFields">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label :for="'calc-field-'+index">{{ field.fieldName }}</label>
                                    <input type="text" class="form-control" :id="'calc-field-'+index"
                                           :placeholder="'Enter '+field.fieldName+' here'" @click="removeClass(index)"
                                           v-model="field['fieldValue']" :class="[field['fieldValue']?'': 'disabled']"
                                    >
                                  </div>
                                </div>
                              </template>

                            </div>
                          </div>
                        </div>


                      </template>

                      <hr>

                      <div class="form-group">
                        <b-form-checkbox
                            v-model="form.lateReceiptSubmission"
                            switch
                        >
                          Late submission
                        </b-form-checkbox>

                      </div>

                      <hr>

                      <!--                pick this from the fields configured in the external cost -->
                      <div class="form-group">
                        <button type="submit" class="btn btn-danger px-2 mr-2"
                                v-if="showSaveReceiptBtn && !disableAction"><i class="fa fa-save"></i> Save details
                        </button>
                        <button type="button" class="btn btn-success px-2"
                                v-if="showApproveReceiptBtn && !disableAction" @click="approveReceiptInfo"><i
                            class="fa fa-check-circle"></i> Approve receipt info (For Super Admin Only)
                        </button>
                      </div>
                      <b-card>
                        <h4 class="card-title">Side note</h4>
                        <p class="text-danger"> * super admin can only approve details once the receipt has been
                          saved </p>
                      </b-card>


                    </div>
                  </form>
                </template>

                <!--  End of selected file details -->

              </div>

            </template>


          </div>
          <div v-if="showChat && driver && selectedExtFile" class="inbox-rightbar">
            <chat-on-receipts :driver="driver" :file="selectedExtFile"
                              @onBackPressed="closeDriverChat"></chat-on-receipts>
          </div>
        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>

    <!--     Comment on status change modal -->
    <template v-if="selectedExtFile">
      <!--        selectedExtFile.file_display_name -->
      <b-modal id="status-comment-modal" @ok="submitExtFileStatusChange" :title="'Add Comment'">
        <textarea class="form-control" v-model="comment" name="" cols="30" rows="10"
                  placeholder="Type comment here ..."></textarea>
      </b-modal>
    </template>

  </div>
</template>

<script>
import Toolbar from "../f-administration/toolbar";
import {emailData} from "../f-administration/data-inbox";
import ChatOnReceipts from "./chat-on-receipts"
import moment from 'moment'
import Multiselect from "vue-multiselect";

import {confirm} from "../../../../utils/functions";
import {financialAdministrationService} from "../../../../apis/financial.administration.service";

/**
 * Read-email component
 */
export default {
  components: {
    Toolbar,
    Multiselect,
    ChatOnReceipts,
  },
  data() {
    return {
      drivers: [],
      admins: [],
      searchError: false,
      selectedDrivers: [],
      search_data: [
        {
          operator: 'LIKE',
          column: 'current_status',
          input: ''
        }
      ],
      emailData: emailData,
      search: '',
      authCode: '',
      adminSearch: true,
      amount: '',
      authCodeError: '',
      searchByInvoiceColumn: false,
      otherSearchFields: false,
      disableAdvanceSearch: false,
      showChat: false,
      driver: null,
      disableAction: false,
      selectedExtFile: null,
      selectedExtFileIndex: 0,
      selectedDriver: null,
      showApproveReceiptBtn: false,
      showSaveReceiptBtn: true,
      receipts: {},
      form: {
        lateReceiptSubmission: false,
        invoiceNumber: '',
        authCode: '',
        invoiceDate: '',
        invoicePlace: '',
      },
      startDate: '',
      endDate: '',
      status: '',
      previousStatus: 'pending',
      comment: '',
      perPage: 10,
      currentPage: 1,
      tokens: []
    };
  },
  computed: {
    itemsForList() {
      return this.$store.getters.GET_SELECTED_DRIVER_EXT_FILES;
    },
    rows() {
      return this.driverExtFiles.length
    },

    driverExtFiles() {
      return this.$store.getters.GET_SELECTED_DRIVER_EXT_FILES
    },
    configuredFields() {
      const data = this.$store.getters.GET_EXTERNAL_DATA_CONFIG_FIELDS;
      if (!data)
        return data;

      return {
        'calculatedFields': JSON.parse(data['calculated_fields']),
        'taxFields': JSON.parse(data['tax_fields']),
      }

    },
  },
  methods: {
    getReceiptDownloadDate(createdAt) {
      return moment(createdAt).calendar();
    },
    getDownloadUrl(token) {
      return process.env.VUE_APP_API_BASE_URL + '/download-receipt-data/' + token
    },

    onDownloadLinkTapped(token) {
      setTimeout(() => {
        this.getDownloadUrl(token)
      }, 2000)
    },
    bulkReceiptApproval() {
      confirm('Are you sure you want to approve all receipts', () => {
        this.$store.dispatch('showLoader')
        financialAdministrationService.approveAllReceipts().then((response) => {
          this.$store.dispatch('hideLoader')
          if (!response.status) {
            this.$store.dispatch('error', {message: response.message, showSwal: true});
            return;
          }
          this.$store.dispatch('success', {message: response.message, showSwal: true});
          this.searchReceipt(true, '');
        });

      });
    },

    downloadReceiptData() {
      this.$store.dispatch('showLoader')

      financialAdministrationService.downloadReceiptData({search: this.search_data}).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.$store.dispatch('success', {message: response.message, showSwal: true})
      });
    },

    getReceiptDownloadTokens() {
      financialAdministrationService.getReceiptDataDownload().then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true});
          this.data = [];
          return;
        }
        this.tokens = response.extra;
      });
    },

    removeClass(index) {
      let element = document.getElementById('calc-field-' + index)
      element.classList.remove('disabled');
      element.setAttribute("required", "");
    },
    addSearch() {
      this.search_data.push({
        operator: 'LIKE',
        column: '',
        input: ''
      })
    },

    selectOptions(i) {
      this.search_data[i].input = '';
      if (this.search_data[i].column === 'current_status') {
        this.search_data[i].operator = '=';
      } else if (this.search_data[i].column === 'approved_by' || this.search_data[i].column === 'accepted_by' || this.search_data[i].column === 'declined_by') {
        this.search_data[i].operator = 'IN';
        this.search_data[i].input = [];
      }
    },
    removeSearch(i) {
      this.search_data.splice(i, 1)
    },

    checkPermission() {
      const user = this.$store.getters.GET_USER_INFO;
      const user_role = user['roles'].filter((e) => e.toLowerCase() === 'developer' || e.toLowerCase() === 'super admin')

      if (user_role.length === 0) {
        this.disableAdvanceSearch = true;
        const permission = this.$store.getters.GET_USER_INFO['user_permission_obj'];
        let user_permission = permission.find((e) => e.name === 'manage receipts')
        if (user_permission) {
          if (user_permission['write'] === 1) {
            this.disableAction = false;
          } else if (user_permission['write'] === 0) {
            this.disableAction = true;
          }
        }
      }
    },

    openDriverChat() {
      this.showChat = true
    },
    closeDriverChat() {
      this.showChat = false
    },
    taxOfConfiguredField(configuredField) {
      return this.configuredFields['taxFields'].find(e => e.refFieldName === configuredField.fieldName)
    },
    formattedDate(value) {
      return moment(value).format('MMMM Do YYYY');
    },

    approveReceiptInfo() {
      if (!this.selectedExtFile) {
        this.$store.dispatch('error', {message: 'Please select a receipt', showSwal: true})
        return;
      }
      confirm("Final approval of the selected receipt", () => {
        this.$store.dispatch('approveExternalFileStatus', this.selectedExtFile['id']).then(() => {
          if (this.adminSearch) {
            this.searchReceipt(true, '')
          } else {
            this.fetchDriverExtFiles(true, '')
          }
        })
      })
    },

    getDrivers(showLoader = true) {
      this.$store.dispatch('getDrivers', {limit: 1000, status: this.status, showLoader: showLoader}).then((res) => {
        this.drivers = res.extra;
      });
    },

    getAdminUserList() {
      this.$store.dispatch("getSystemAdminUsers").then((res) => {
        this.admins = res.extra;
      });
    },

    goToPage(link) {
      if (link.url) {
        if (this.adminSearch) {
          this.searchReceipt(true, link.url)
        } else {
          this.fetchDriverExtFiles(true, link.url)
        }
      }
    },

    handleSubmit() {
      this.configuredFields['calculatedFields'] = this.configuredFields['calculatedFields'].map((e) => {
        if (!e['fieldValue']) {
          e['fieldValue'] = 0;
        }
        return e;
      })
      confirm('save receipt', () => {
        const request = {
          'calculated_fields': this.configuredFields['calculatedFields'],
          'data_on_receipt': this.form,
          'invoice_number': this.form.invoiceNumber,
          'auth_code': this.form.authCode,
        }
        this.$store.dispatch('saveDataOnExternalReceipts', {
          fileId: this.selectedExtFile['id'],
          request
        }).then(() => {
          // this.searchReceipt()
        })


      })
    },

    onExternalFileTapped(file, index) {
      this.selectedExtFile = file;
      this.selectedDriver = file['driver_user']
      this.selectedExtFileIndex = index;
      this.scrollToDiv()
    },

    onDriverSelected(driver) {
      this.startDate = moment().startOf('week').isoWeekday(1).toISOString();
      this.endDate = moment().endOf('week').toISOString();
      this.selectedExtFile = null;
      this.selectedExtFileIndex = 0;
      this.showChat = false;
      this.driver = driver;
    },

    searchByInvoice(showLoader = true) {
      this.searchByInvoiceColumn = true;
      this.selectedExtFile = null;
      this.otherSearchFields = false;
      this.$store.dispatch('searchReceiptByInvoiceNo', {
        showLoader: showLoader,
        request: {
          search: this.search,
        }
      }).then((res) => {
        this.receipts = res;
      })
    },

    searchReceipt(showLoader = true, url = null) {
      this.adminSearch = true;
      this.$store.dispatch('searchReceiptData', {
        showLoader: showLoader,
        url: url,
        search: this.search_data
      }).then((res) => {
        this.receipts = res
        this.onExternalFileTapped(this.driverExtFiles[this.selectedExtFileIndex], this.selectedExtFileIndex)
      });
    },


    scrollToDiv() {
      setTimeout(() => {
        document
            .getElementById("details")
            .scrollIntoView({behavior: "smooth"});
      }, 500)

    },

    searchByAuthCode(showLoader = true) {
      this.searchByInvoiceColumn = true;
      this.selectedExtFile = null;
      this.otherSearchFields = false;
      this.$store.dispatch('searchReceiptByInvoiceNo', {
        showLoader: showLoader,
        request: {
          auth_code: this.authCode,
        }
      }).then((res) => {
        this.receipts = res;
      })
    },

    searchByAmount(showLoader = true) {
      this.searchByInvoiceColumn = true;
      this.selectedExtFile = null;
      this.otherSearchFields = false;
      this.$store.dispatch('searchReceiptByInvoiceNo', {
        showLoader: showLoader,
        request: {
          amount: this.amount,
        }
      }).then((res) => {
        this.receipts = res;
      })
    },


    fetchDriverExtFiles(showLoader = true, url = null) {
      this.searchByInvoiceColumn = false;
      this.otherSearchFields = true;
      this.adminSearch = false;
      this.selectedExtFile = null;
      this.$store.dispatch('fetchAllUploadedExternalFiles', {
        showLoader: showLoader,
        url: url,
        request: {
          drivers: this.selectedDrivers,
          start_date: this.startDate,
          end_date: this.endDate,
          status: this.status
        }
      }).then((res) => {
        this.receipts = res
        this.onExternalFileTapped(this.driverExtFiles[this.selectedExtFileIndex], this.selectedExtFileIndex)
      });
    },

    updateExternalFileStatus(newStatus, requestComment = true) {
      /// call modal for admin to add comment .....
      this.previousStatus = this.status;
      this.status = newStatus;
      if (newStatus === "accepted") {
        this.comment = "ACCEPTED";
      } else {
        this.comment = '';
      }
      if (requestComment) {
        this.$bvModal.show('status-comment-modal')
        return;
      }

      this.submitExtFileStatusChange();

    },

    submitExtFileStatusChange() {
      this.$store.dispatch('updateExternalFileStatus', {
        fileId: this.selectedExtFile.id,
        request: {
          status: this.status,
          comment: this.comment,
        }
      }).then(() => {
        this.status = this.previousStatus;
        if (this.adminSearch) {
          this.searchReceipt(true, '')
        } else {
          this.fetchDriverExtFiles(true, '')
        }
      });
    },

    onFileSelected(value) {
      if (value) {
        let dataOnReceipts = value['data_on_ext_receipts'];

        if (dataOnReceipts) {
          //"{\"lateReceiptSubmission\":false,\"invoiceNumber\":\"123466\",\"invoiceDate\":\"2021-11-13\",\"invoicePlace\":\"Accra\"}"
          dataOnReceipts = JSON.parse(dataOnReceipts);
          this.form.invoiceNumber = dataOnReceipts['invoiceNumber']
          this.form.authCode = dataOnReceipts['authCode']
          this.form.lateReceiptSubmission = dataOnReceipts['lateReceiptSubmission']
          this.form.invoiceDate = dataOnReceipts['invoiceDate']
          this.form.invoicePlace = dataOnReceipts['invoicePlace']
          this.showApproveReceiptBtn = true;
        } else {
          this.form.invoiceNumber = ''
          this.form.authCode = ''
          this.form.lateReceiptSubmission = ''
          this.form.invoiceDate = ''
          this.form.invoicePlace = ''
          this.showApproveReceiptBtn = false
        }

        /// calculated taxes
        let calculated_and_tax_fields = value['calculated_and_tax_fields']
        if (calculated_and_tax_fields) {
          calculated_and_tax_fields = JSON.parse(calculated_and_tax_fields)
          //  "[{"fieldName":"Brandstof","fieldType":"cost","weeklyPayment":true,"fAdministration":true,"fieldValue":"80.99","tax":{"refFieldName":"Brandstof","refFieldType":"cost","taxType":"upper","formula":"ref\/121*21","weeklyPayment":false,"fAdministration":true,"value":14.056115702479337}}]"
          //   this.configuredFields.calculatedFields.findIndex(e => e.)
          calculated_and_tax_fields.forEach(d => {
            const index = this.configuredFields.calculatedFields.findIndex(e => e.fieldName === d.fieldName)
            this.configuredFields.calculatedFields[index]['fieldValue'] = d['fieldValue']
          })
        } else {
          const data = this.$store.getters.GET_EXTERNAL_DATA_CONFIG_FIELDS;
          if (!data) {
            this.$store.dispatch('error', {
              message: 'Configurations for receipt fields has not been done',
              showSwal: true
            })
            return
          }
          this.configuredFields.calculatedFields = JSON.parse(data['calculated_fields'])

        }
        if (dataOnReceipts && calculated_and_tax_fields && (value['current_status'] === 'approved')) {
          this.showApproveReceiptBtn = false;
        }
      }
    }


  },
  watch: {
    '$store.getters.GET_RECEIPT_MESSAGES': function (value) {
      this.searchReceipt(false)
    },
    driver: function () {
      this.searchReceipt();
    },
    selectedExtFile: function (value) {
      this.onFileSelected(value)
    },
  },
  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      let {data} = event;
      if (data.title === 'completed_receipt_data') {
        this.getReceiptDownloadTokens();
      }
    });
  },
  created() {
    this.$store.dispatch('getExternalDataConfigFields')
    this.$store.dispatch('setPageTitle', 'Search Receipt')
    this.searchReceipt(false);
    this.getDrivers(true);
    this.getAdminUserList(true);
    this.checkPermission();
    this.getReceiptDownloadTokens();


  },
};
</script>

<style>
.selected {
  border: 2px solid #EE4C4A;
  padding: 5px;
  border-radius: 5px;
}

.disabled {
  background-color: #f1f5f7;
  opacity: 1;
}
</style>

